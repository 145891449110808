import React from "react";
import { isEmpty } from "lodash";

import { EButtonSize } from "@components/Button/types";
import CtasCollection from "@components/ContentfulComponents/ComponentTextAssetAndCtas/CtasCollection/CtasCollection.component";
import { renderRichTextWithLinks } from "@shared/contentful/contentful-render.utils";
import { replaceAllBreakSpaces } from "@shared/text/text.utils";

import { ITextAssetAndCtasCflData } from "../type";

import styles from "@components/ContentfulComponents/ComponentTextAssetAndCtas/HighlightText/highlight-text.module.scss";

export type THighlightText = Pick<
	ITextAssetAndCtasCflData,
	"overlineText" | "richTextContent" | "openLinksInANewTab" | "ctasCollection"
>;

const HighlightText = ({
	overlineText,
	richTextContent,
	openLinksInANewTab,
	ctasCollection,
}: THighlightText) => {
	if (!richTextContent || isEmpty(richTextContent.json)) return null;

	return (
		<div className={styles.highLightWrapper} data-testid="HighlightText_wrapper">
			<div className="container-grid large-grid">
				<div className={styles.highlightContentWrapper}>
					{overlineText && (
						<p className={styles.highlightOverline} data-testid="HighlightText_overline-text">
							{replaceAllBreakSpaces(overlineText)}
						</p>
					)}
					{renderRichTextWithLinks({ richTextDocument: richTextContent.json, openLinksInANewTab })}
					<div className={styles.highlightCta}>
						<CtasCollection ctasCollection={ctasCollection} size={EButtonSize.Sm} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default HighlightText;
