import {
	EComponentLinkType,
	ILinkCflData,
} from "@components/ContentfulComponents/ComponentLink/type";
import { getContentfulIcon } from "@shared/contentful/slug.utils";
import { ICollection } from "@shared/contentful/contentful.types";
import { convertRelOptions, convertTargetOptions } from "@shared/contentful/contentful.utils";
import CallToAction from "@components/CallToAction/CallToAction.component";
import { EButtonSize, EButtonType } from "@components/Button/types";

interface ICtasCollection {
	ctasCollection?: ICollection<ILinkCflData>;
	size: EButtonSize;
}

const CtasCollection = ({ ctasCollection, size }: ICtasCollection) => {
	if (!ctasCollection?.items?.length) return null;

	return (
		<>
			{ctasCollection.items.map((cta) => {
				if (!cta) return null;

				const { label, url, icon, relOptions, targetOptions, type } = cta;

				const ctaIcon = getContentfulIcon(icon);
				const ctaRel = convertRelOptions(relOptions);
				const ctaTarget = convertTargetOptions(targetOptions);

				if (!label || !url) return null;

				const buttonType =
					type === EComponentLinkType.Secondary ? EButtonType.Secondary : EButtonType.Primary;

				return (
					<CallToAction
						buttonSize={size}
						buttonType={buttonType}
						callToActionLabel={label}
						callToActionUrl={url}
						icon={ctaIcon}
						rel={ctaRel}
						target={ctaTarget}
						key={cta.sys?.id}
					/>
				);
			})}
		</>
	);
};

export default CtasCollection;
