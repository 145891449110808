import React from "react";
import Button from "@components/Button/Button.component";
import Icon, { EIcon } from "@components/Icon/Icon.component";
import { EButtonSize, EButtonType } from "@components/Button/types";

interface ICallToAction {
	callToActionUrl: string;
	callToActionLabel: string;
	buttonType: EButtonType;
	buttonSize: EButtonSize;
	target: string | undefined;
	rel?: string;
	icon?: EIcon;
}

const CallToAction = ({
	buttonSize,
	buttonType,
	callToActionLabel,
	callToActionUrl,
	icon,
	rel,
	target,
}: ICallToAction) => {
	return (
		<Button
			as={"a"}
			href={callToActionUrl}
			buttonType={buttonType}
			buttonSize={buttonSize}
			target={target}
			rel={rel}
			data-testid="CallToAction_btn"
		>
			{callToActionLabel}
			{icon && <Icon icon={icon} size={24} noHover noActive />}
		</Button>
	);
};

export default CallToAction;
